import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { StyleSheet, Dimensions, ScrollView } from 'react-native';
import { Layout, Button, Text, Divider, Card, Icon } from '@ui-kitten/components';
import * as Yup from 'yup';
import { useIsMobile } from '../core/responsive.utils';
import { Formik } from 'formik';
import { TextInputField } from './input/TextInputField';
import { UIStatusWrapper } from './ui-status';
import Autocomplete from './Autocomplete';
import {
  countryStateCityCurrencyStore,
  inventoryStore,
  skuTableStore,
  warehouseAccountStore,
} from '../store';
import { useApolloClient } from '@apollo/client';
import { SKU_CODES_EXIST, unifiedAlert, useSearchHsCode } from '../core/utils/utils';
import { remove } from 'ramda';
import { observer } from 'mobx-react';
import { RadioGroupInputField } from './input/RadioGroupInputField';
import DeclareCountryInput from './DeclareCountryInput';

const windowHeight = Dimensions.get('window').height;

// const SN_CODE_PROTOCOL_OPTIONS = [
//   { key: '', val: 'None' },
//   { key: '01', val: '15 digits' },
//   { key: '02', val: '18 digits' },
//   { key: '03', val: '12 digits' },
// ];

export const getSkuSchema = (CURRENCIES, COUNTRIES, UOMS, warehouseAccountIds) =>
  Yup.object({
    warehouseAccountId: Yup.number()
      .oneOf(warehouseAccountIds, 'Warehouse Account ID invalid')
      .required(),
    sku_code: Yup.string()
      .matches(
        /^[A-Z0-9\-\_\#\*\/\&\+\?\.\(\)]+$/,
        'SKU code only allows uppercase letters, numbers, -, _, #, *, /, &, +, ?, ., ( and ).',
      )
      .required('Sku code is required')
      .min(3, 'SKU code must be longer than 3 characters'),
    sku_name: Yup.string()
      .required('SKU name is requried')
      .min(3, 'SKU name must be longer than 3 characters')
      .max(50, 'SKU name must be less than 50 characters'),
    product_code: Yup.string()
      .min(3, 'Product code must be longer than 3 characters')
      .matches(/[a-zA-Z0-9]+/, ({ label }) => `${label} only allow letters and number.`),
    weight: Yup.number()
      .required()
      .min(0, 'Weight too small')
      .max(Number.MAX_SAFE_INTEGER, 'Weight too large'),
    length: Yup.number()
      .required()
      .min(0, 'Length too small')
      .max(Number.MAX_SAFE_INTEGER, 'Length too large'),
    width: Yup.number()
      .required()
      .min(0, 'Width too small')
      .max(Number.MAX_SAFE_INTEGER, 'Length too large'),
    height: Yup.number()
      .required()
      .min(0, 'Height too small')
      .max(Number.MAX_SAFE_INTEGER, 'Height too large'),
    // is_brand: Yup.string().required().oneOf(['Y', 'N']).default('N'),
    // brand_name: Yup.string()
    //   .min(2, 'Must be longer than 1 characters')
    //   .when('is_brand', {
    //     is: 'Y',
    //     then: Yup.string().required('Is branded is required'),
    //   }),
    origin_country: Yup.string()
      .length(2, 'Country of origin nust be longer than a 2 character code')
      .oneOf(COUNTRIES, 'Not a valid country of origin code')
      .required('Country of origin is required'),

    uom: Yup.string().oneOf(
      UOMS.map((uom) => uom.code),
      'Not a valid unit of measurement',
    ),
    logistics_package: Yup.boolean(),
    // uses: Yup.string().trim().min(2, 'Must be longer than 2 characters'),
    // material: Yup.string().trim().min(2, 'Must be longer than 2 characters'),
    // include_battery: Yup.string().oneOf(['Y', 'N']).default('N'),
    // Not yet supported
    // battery_resource: Yup.string()
    //   .url()
    //   .matches(/.*\.(zip)|(rar)$/i, 'Need to be either a compressed ZIP or RAR file')
    //   .when('include_battery', (include_battery, schema) =>
    //     include_battery === 'Y' ? schema.required() : schema.optional(),
    //   ),
    // Not yet supported
    // expired_date: Yup.string().oneOf(['Y', 'N']).default('N'),
    // Not yet supported
    // expired_max: Yup.number().min(1).default(null),
    // Not yet supported
    // include_batch: Yup.string().oneOf(['Y', 'N']).default('N'),
    // Not yet supported
    // picture_url: Yup.string().url(),
    declare_country_list: Yup.array()
      .of(
        Yup.object().shape({
          export_country: Yup.string()
            .oneOf(COUNTRIES, 'Invalid export country code')
            .required('Export country is Required')
            .default('CN'),
          export_port: Yup.string().required('Export port is required'),
          export_declare: Yup.string().required('Export declaration is required'),
          export_hscode: Yup.string().required('Export HS-CODE is required'),
          export_declare_value: Yup.string().required('Export declare value is required'),
          export_currency: Yup.string()
            .oneOf(CURRENCIES, 'Export declare currency not valid')
            .required('Export declare currency is required')
            .default('USD'),
          country: Yup.string()
            .oneOf(COUNTRIES, 'Invalid country code')
            .required('Import country is required')
            .default('AU'),
          import_port: Yup.string().required('Import port is required'),
          import_declare: Yup.string().required('Import declaration is required.'),
          hs_code: Yup.string().required(
            'Import HS-CODE is required. If you are not sure, please look it up.',
          ),
          declare_value: Yup.number()
            .min(0, 'Import declare value too small')
            .max(Number.MAX_SAFE_INTEGER, 'Import declare value too large')
            .required('Import declare value'),
          currency: Yup.string()
            .oneOf(
              CURRENCIES,
              `Import declare currency not valid, has to be one of [${CURRENCIES}]`,
            )
            .required('Import declare currency')
            .default('USD'),
        }),
      )
      .min(1),
  });

export default observer(({ onDismiss, onSubmit, warehouseAccount }) => {
  const isMobile = useIsMobile();

  const client = useApolloClient();

  const searchHsCode = useSearchHsCode();

  return (
    <ScrollView keyboardShouldPersistTaps={'handled'} style={styles.formContainer}>
      <Layout style={styles.form}>
        <Text category="h5" style={styles.title}>
          Add SKU
        </Text>
        <Divider />
        <UIStatusWrapper
          status={{
            error: inventoryStore.error,
            indeterminate: inventoryStore.loading,
          }}>
          <Formik
            isInitialValid={false}
            initialValues={{
              warehouseAccountId: warehouseAccount.id,
              sku_code: '',
              sku_name: '',
              product_code: '',
              weight: '',
              length: '',
              width: '',
              height: '',
              // is_brand: 'N',
              // brand_name: '',
              origin_country: '',
              uom: '',
              logistics_package: true,
              // uses: '',
              // material: '',
              // include_battery: 'N',
              // Not yet supported
              // battery_resource: '',
              // Not yet supported
              // expired_date: 'N',
              // Not yet supported
              // expired_max: 90,
              // Not yet supported
              // include_batch: '',
              // Not yet supported
              // picture_ur: '',
              declare_country_list: [
                {
                  export_country: 'AU',
                  export_declare: '',
                  export_hscode: '',
                  export_declare_value: '',
                  export_currency: '',
                  country: 'AU',
                  import_declare: '',
                  hs_code: '',
                  declare_value: '',
                  currency: '',
                },
              ],
            }}
            validationSchema={getSkuSchema(
              countryStateCityCurrencyStore.currencies,
              countryStateCityCurrencyStore.countryCodes,
              inventoryStore.getUoms(warehouseAccount.id),
              warehouseAccountStore.warehouseAccountIds,
            )}
            onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
              setSubmitting(true);
              try {
                const {
                  data: { skuCodesExist },
                } = await client.query({
                  query: SKU_CODES_EXIST,
                  variables: {
                    warehouseAccountId: warehouseAccount.id,
                    skuCodes: [values.sku_code],
                  },
                });
                if (skuCodesExist) {
                  const msg = 'SKU Code already exist, please use a different one';
                  setFieldError('sku_code', msg);
                  unifiedAlert(msg);
                  return;
                }

                if (values.product_code) {
                  const {
                    data: { skuCodesExist: productCodesExist },
                  } = await client.query({
                    query: SKU_CODES_EXIST,
                    variables: {
                      warehouseAccountId: warehouseAccount.id,
                      productCodes: [values.product_code],
                    },
                  });
                  if (productCodesExist) {
                    const msg = 'Product Code already exist, please use a different one';
                    setFieldError('product_code', msg);
                    unifiedAlert(msg);
                    return;
                  }
                }

                const data = await inventoryStore.addItem(warehouseAccount.id, values);
                skuTableStore.fetchItems();
                unifiedAlert(`${data?.sku_code} (${data?.sku_id}) Created. `);
                resetForm();
                onSubmit();
              } catch (e) {
                unifiedAlert(`Error:\n${e.message}`);
              }
              setSubmitting(false);
            }}>
            {(props) => {
              const uoms = inventoryStore.getUoms(warehouseAccount.id);

              return (
                <>
                  <TextInputField
                    autoFocus
                    name="sku_code"
                    placeholder="ABDE-A322"
                    style={styles.field}
                    label="SKU code"
                    textTransform="uppercase"
                    {...props}
                  />
                  <TextInputField
                    placeholder="T-shirt small"
                    name="sku_name"
                    style={styles.field}
                    label="SKU Name"
                    {...props}
                  />
                  <TextInputField
                    autoFocus
                    placeholder="4987188506042"
                    name="product_code"
                    style={styles.field}
                    label="Product code"
                    {...props}
                  />

                  {/* <TextInputField
                    placeholder="Plastic"
                    name="material"
                    style={styles.field}
                    label="Material"
                    {...props}
                  /> */}
                  {/* <TextInputField
                    placeholder=""
                    name="uses"
                    style={styles.field}
                    label="Usage"
                    {...props}
                  /> */}
                  {/* <RadioGroupInputField
                    label="Branded"
                    name="is_brand"
                    style={styles.field}
                    options={[
                      { key: 'N', val: 'No' },
                      { key: 'Y', val: 'Yes' },
                    ]}
                    {...props}
                  /> */}
                  {/* {props.values.is_brand === 'Y' ? (
                    <TextInputField
                      placeholder=""
                      name="brand_name"
                      style={styles.field}
                      label="Brand name"
                      {...props}
                    />
                  ) : null} */}
                  <Autocomplete
                    name="origin_country"
                    label="Country of origin"
                    defautValue={props.values.origin_country}
                    error={props.errors.origin_country}
                    touched={props.touched.origin_country}
                    style={styles.field}
                    placeholder="Select a country"
                    options={countryStateCityCurrencyStore.countryCodes}
                    onSelect={props.handleChange('origin_country')}
                    onBlur={props.handleBlur('origin_country')}
                  />
                  <Autocomplete
                    name="uom"
                    label="Unit of Measurement"
                    defautValue={props.values.uom}
                    error={props.errors.uom}
                    touched={props.touched.uom}
                    style={styles.field}
                    placeholder="Select a unit"
                    options={uoms}
                    getValue={(uom) => uom.code}
                    getTitle={(uom) => uom.name_en}
                    onSelect={props.handleChange('uom')}
                    onBlur={props.handleBlur('uom')}
                  />

                  <TextInputField
                    name="weight"
                    style={styles.field}
                    label="Weight (kg)"
                    {...props}
                  />
                  <TextInputField
                    name="length"
                    style={styles.field}
                    label="Length (cm)"
                    {...props}
                  />
                  <TextInputField name="width" style={styles.field} label="Width (cm)" {...props} />
                  <TextInputField
                    name="height"
                    style={styles.field}
                    label="Height (cm)"
                    {...props}
                  />
                  {/* <RadioGroupInputField
                    label="Include battery"
                    name="include_battery"
                    style={styles.field}
                    options={[
                      { key: 'N', val: 'No' },
                      { key: 'Y', val: 'Yes' },
                    ]}
                    {...props}
                  /> */}
                  {/* <TextInputField
                    placeholder="Blue, 50cm"
                    name="specification"
                    style={styles.field}
                    label="Specification"
                    {...props}
                  /> */}
                  <RadioGroupInputField
                    label="Logistics packaging"
                    name="logistics_package"
                    style={styles.field}
                    options={[
                      { key: false, val: 'No' },
                      { key: true, val: 'Yes' },
                    ]}
                    {...props}
                  />
                  {/* Not yet supported */}
                  {/* <Select
                    name="include_batch"
                    style={styles.field}
                    label="Batch management"
                    options={[
                      { key: 'Y', val: 'yes' },
                      { key: 'N', val: 'no' },
                    ]}
                    {...props}
                  /> */}
                  {/* Not yet supported */}
                  {/* <Select
                    name="expired_date"
                    style={styles.field}
                    label="Has expirey date"
                    options={[
                      { key: 'Y', val: 'yes' },
                      { key: 'N', val: 'no' },
                    ]}
                    {...props}
                  /> */}
                  {/* Not yet supported */}
                  {/* <TextInputField
                    name="picture_url"
                    style={styles.field}
                    label="Picture URL"
                    placeholder="http://productpic.com/123.png"
                    {...props}
                  /> */}

                  {/* Not yet supported */}
                  {/* <TextInputField
                    name="battery_resource"
                    style={styles.field}
                    label="Battery documents"
                    placeholder="http://batterydoc.com/123.zip"
                    {...props}
                  />
                  */}
                  <Divider />
                  {props.values.declare_country_list.map((c, i) => (
                    <DeclareCountryInput
                      key={'declare-country-' + i}
                      onRemove={() =>
                        props.setFieldValue(
                          'declare_country_list',
                          remove(i, i + 1, props.values.declare_country_list),
                        )
                      }
                      values={props.values.declare_country_list[i]}
                      touched={props.touched.declare_country_list?.[i]}
                      errors={props.errors.declare_country_list?.[i]}
                      handleChange={(valKey) =>
                        props.handleChange(`declare_country_list[${i}].${valKey}`)
                      }
                      handleBlur={(valKey) =>
                        props.handleBlur(`declare_country_list[${i}].${valKey}`)
                      }
                    />
                  ))}
                  <Button
                    size="medium"
                    appearance="ghost"
                    accessoryLeft={(props) => <Icon {...props} name="plus-circle-outline" />}
                    onPress={() =>
                      props.setFieldValue('declare_country_list', [
                        ...props.values.declare_country_list,
                        {},
                      ])
                    }>
                    Add import and export countries
                  </Button>
                  <Divider />
                  <Layout
                    style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                    <Button
                      status="primary"
                      loading={props.isSubmitting}
                      onPress={props.handleSubmit}
                      disabled={props.isSubmitting || !props.isValid}
                      style={styles.button}>
                      Submit
                    </Button>
                    <Button
                      status="basic"
                      loading={props.isSubmitting}
                      onPress={onDismiss}
                      disabled={props.isSubmitting}
                      style={styles.button}>
                      Cancel
                    </Button>
                    <Button
                      status="danger"
                      appearance="ghost"
                      disabled={props.isSubmitting}
                      onPress={props.handleReset}
                      style={styles.button}>
                      Reset
                    </Button>
                  </Layout>
                </>
              );
            }}
          </Formik>
        </UIStatusWrapper>
      </Layout>
    </ScrollView>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  form: {
    alignSelf: 'center',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  controlContainer: {
    borderRadius: 4,
    margin: 2,
    padding: 6,
    backgroundColor: '#3366FF',
  },
  formContainer: {
    maxHeight: 0.9 * windowHeight,
    overflowY: 'auto',
  },
});
