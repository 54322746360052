import { gql } from '@apollo/client';
import { flow, makeAutoObservable } from 'mobx';

const SHIPPING_CHANNELS = gql`
  query shippingChannes($warehouseAccountId: ID!) {
    shippingChannels(warehouseAccountId: $warehouseAccountId) {
      id
      warehouseAccountId
      warehouse_code
      provider
      name
      autoPack
      supportPacking
      singlePackageOnly
      requireExportInfo
      allowUserShipmentOrder
      chargeByCubicWeight
      cubicWeightCoefficient
      deliveryWindow
      dangerousGoods
      handUnload
      tailgate
      apiUrl
      apiKey
      apiPassword
      apiToken
      apiSecret
      serviceCode
      serviceOption
      facility
      accountNumber
      productId
      wsdlUrl
      webServiceUrl
      signatureCode
      accountCode
      accountState
      serviceLevel
      username
      password
      ftpDirectory
      serviceType
      numberPrefix
      sftpAddress
    }
  }
`;

const ADD_SHIPPING_CHANNEL = gql`
  mutation addShippingChannel(
    $warehouseAccountId: ID!
    $warehouse_code: ID
    $provider: String!
    $name: String!
    $autoPack: Boolean!
    $supportPacking: Boolean!
    $singlePackageOnly: Boolean!
    $requireExportInfo: Boolean!
    $allowUserShipmentOrder: Boolean!
    $chargeByCubicWeight: Boolean!
    $cubicWeightCoefficient: Float
    $deliveryWindow: Boolean!
    $dangerousGoods: Boolean!
    $handUnload: Boolean!
    $tailgate: Boolean!
    $apiUrl: String
    $apiKey: String
    $apiPassword: String
    $apiToken: String
    $apiSecret: String
    $serviceCode: String
    $serviceOption: String
    $facility: String
    $accountNumber: String
    $productId: String
    $wsdlUrl: String
    $webServiceUrl: String
    $signatureCode: String
    $accountCode: String
    $accountState: String
    $serviceLevel: String
    $username: String
    $password: String
    $ftpDirectory: String
    $serviceType: String
    $numberPrefix: String
    $sftpAddress: String
  ) {
    addShippingChannel(
      warehouseAccountId: $warehouseAccountId
      warehouse_code: $warehouse_code
      provider: $provider
      name: $name
      autoPack: $autoPack
      supportPacking: $supportPacking
      singlePackageOnly: $singlePackageOnly
      requireExportInfo: $requireExportInfo
      allowUserShipmentOrder: $allowUserShipmentOrder
      chargeByCubicWeight: $chargeByCubicWeight
      cubicWeightCoefficient: $cubicWeightCoefficient
      deliveryWindow: $deliveryWindow
      dangerousGoods: $dangerousGoods
      handUnload: $handUnload
      tailgate: $tailgate
      apiUrl: $apiUrl
      apiKey: $apiKey
      apiPassword: $apiPassword
      apiToken: $apiToken
      apiSecret: $apiSecret
      serviceCode: $serviceCode
      serviceOption: $serviceOption
      facility: $facility
      accountNumber: $accountNumber
      productId: $productId
      wsdlUrl: $wsdlUrl
      webServiceUrl: $webServiceUrl
      signatureCode: $signatureCode
      accountCode: $accountCode
      accountState: $accountState
      serviceLevel: $serviceLevel
      username: $username
      password: $password
      ftpDirectory: $ftpDirectory
      serviceType: $serviceType
      numberPrefix: $numberPrefix
      sftpAddress: $sftpAddress
    ) {
      id
    }
  }
`;

const UPDATE_SHIPPING_CHANNEL = gql`
  mutation updateShippingChannel(
    $id: ID!
    $warehouseAccountId: ID!
    $warehouse_code: ID
    $autoPack: Boolean!
    $supportPacking: Boolean!
    $singlePackageOnly: Boolean!
    $requireExportInfo: Boolean!
    $allowUserShipmentOrder: Boolean!
    $chargeByCubicWeight: Boolean!
    $cubicWeightCoefficient: Float
    $deliveryWindow: Boolean!
    $dangerousGoods: Boolean!
    $handUnload: Boolean!
    $tailgate: Boolean!
    $apiUrl: String
    $apiKey: String
    $apiPassword: String
    $apiToken: String
    $apiSecret: String
    $serviceCode: String
    $serviceOption: String
    $facility: String
    $accountNumber: String
    $productId: String
    $wsdlUrl: String
    $webServiceUrl: String
    $signatureCode: String
    $accountCode: String
    $accountState: String
    $serviceLevel: String
    $username: String
    $password: String
    $ftpDirectory: String
    $serviceType: String
    $numberPrefix: String
    $sftpAddress: String
  ) {
    updateShippingChannel(
      id: $id
      warehouseAccountId: $warehouseAccountId
      warehouse_code: $warehouse_code
      autoPack: $autoPack
      supportPacking: $supportPacking
      singlePackageOnly: $singlePackageOnly
      requireExportInfo: $requireExportInfo
      allowUserShipmentOrder: $allowUserShipmentOrder
      chargeByCubicWeight: $chargeByCubicWeight
      cubicWeightCoefficient: $cubicWeightCoefficient
      deliveryWindow: $deliveryWindow
      dangerousGoods: $dangerousGoods
      handUnload: $handUnload
      tailgate: $tailgate
      apiUrl: $apiUrl
      apiKey: $apiKey
      apiPassword: $apiPassword
      apiToken: $apiToken
      apiSecret: $apiSecret
      serviceCode: $serviceCode
      serviceOption: $serviceOption
      facility: $facility
      accountNumber: $accountNumber
      productId: $productId
      wsdlUrl: $wsdlUrl
      webServiceUrl: $webServiceUrl
      signatureCode: $signatureCode
      accountCode: $accountCode
      accountState: $accountState
      serviceLevel: $serviceLevel
      username: $username
      password: $password
      ftpDirectory: $ftpDirectory
      serviceType: $serviceType
      numberPrefix: $numberPrefix
      sftpAddress: $sftpAddress
    ) {
      id
    }
  }
`;

const DELETE_SHIPPING_CHANNEL = gql`
  mutation deleteShippingChannel($id: ID!, $warehouseAccountId: ID!) {
    deleteShippingChannel(id: $id, warehouseAccountId: $warehouseAccountId) {
      id
    }
  }
`;

export class ShippingChannelStore {
  loading = false;
  error = null;
  cachedShippingChannels = null;
  client = null;

  constructor(client, warehouseAccountId) {
    this.client = client;
    this.warehouseAccountId = warehouseAccountId;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        warehouseAccountId: false,
        client: false,
      },
      { autoBind: true },
    );
  }

  clearCache() {
    this.cachedShippingChannels = null;
  }

  get shippingChannels() {
    if (!this.cachedShippingChannels) {
      this.fetchItems();
    }
    return this.cachedShippingChannels || [];
  }

  *fetchItems() {
    try {
      this.loading = true;
      const data = yield this.client.query({
        query: SHIPPING_CHANNELS,
        variables: {
          warehouseAccountId: this.warehouseAccountId,
        },
        fetchPolicy: 'no-cache',
      });
      this.cachedShippingChannels = data?.data?.shippingChannels;
      this.error = null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  *addShippingChannel({
    warehouseAccountId,
    warehouse_code,
    provider,
    name,
    autoPack,
    supportPacking,
    singlePackageOnly,
    requireExportInfo,
    allowUserShipmentOrder,
    chargeByCubicWeight,
    cubicWeightCoefficient,
    deliveryWindow,
    dangerousGoods,
    handUnload,
    tailgate,
    apiUrl,
    apiKey,
    apiPassword,
    apiToken,
    apiSecret,
    serviceCode,
    serviceOption,
    facility,
    accountNumber,
    productId,
    wsdlUrl,
    webServiceUrl,
    signatureCode,
    accountCode,
    accountState,
    serviceLevel,
    username,
    password,
    ftpDirectory,
    serviceType,
    numberPrefix,
    sftpAddress,
  }) {
    try {
      this.loading = true;
      yield this.client.mutate({
        mutation: ADD_SHIPPING_CHANNEL,
        variables: {
          warehouseAccountId,
          warehouse_code,
          provider,
          name,
          autoPack,
          supportPacking,
          singlePackageOnly,
          requireExportInfo,
          allowUserShipmentOrder,
          chargeByCubicWeight,
          cubicWeightCoefficient,
          deliveryWindow,
          dangerousGoods,
          handUnload,
          tailgate,
          apiUrl,
          apiKey,
          apiPassword,
          apiToken,
          apiSecret,
          serviceCode,
          serviceOption,
          facility,
          accountNumber,
          productId,
          wsdlUrl,
          webServiceUrl,
          signatureCode,
          accountCode,
          accountState,
          serviceLevel,
          username,
          password,
          ftpDirectory,
          serviceType,
          numberPrefix,
          sftpAddress,
        },
      });
      this.cachedShippingChannels = null;
      this.error = null;
    } catch (error) {
      this.error = error;
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *updateShippingChannel({
    id,
    warehouseAccountId,
    warehouse_code,
    autoPack,
    supportPacking,
    singlePackageOnly,
    requireExportInfo,
    allowUserShipmentOrder,
    chargeByCubicWeight,
    cubicWeightCoefficient,
    deliveryWindow,
    dangerousGoods,
    handUnload,
    tailgate,
    apiUrl,
    apiKey,
    apiPassword,
    apiToken,
    apiSecret,
    serviceCode,
    serviceOption,
    facility,
    accountNumber,
    productId,
    wsdlUrl,
    webServiceUrl,
    signatureCode,
    accountCode,
    accountState,
    serviceLevel,
    username,
    password,
    ftpDirectory,
    serviceType,
    numberPrefix,
    sftpAddress,
  }) {
    try {
      this.loading = true;
      yield this.client.mutate({
        mutation: UPDATE_SHIPPING_CHANNEL,
        variables: {
          id,
          warehouseAccountId,
          warehouse_code,
          autoPack,
          supportPacking,
          singlePackageOnly,
          requireExportInfo,
          allowUserShipmentOrder,
          chargeByCubicWeight,
          cubicWeightCoefficient,
          deliveryWindow,
          dangerousGoods,
          handUnload,
          tailgate,
          apiUrl,
          apiKey,
          apiPassword,
          apiToken,
          apiSecret,
          serviceCode,
          serviceOption,
          facility,
          accountNumber,
          productId,
          wsdlUrl,
          webServiceUrl,
          signatureCode,
          accountCode,
          accountState,
          serviceLevel,
          username,
          password,
          ftpDirectory,
          serviceType,
          numberPrefix,
          sftpAddress,
        },
      });
      this.cachedShippingChannels = null;
      this.error = null;
    } catch (error) {
      this.error = error;
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *deleteShippingChannel(id, warehouseAccountId) {
    try {
      this.loading = true;
      yield this.client.mutate({
        mutation: DELETE_SHIPPING_CHANNEL,
        variables: {
          id,
          warehouseAccountId,
        },
      });
      this.cachedShippingChannels = null;
      this.error = null;
    } catch (error) {
      this.error = error;
      throw error;
    } finally {
      this.loading = false;
    }
  }
}
