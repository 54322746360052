import '@expo/match-media';
import React, { memo, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import {
  Layout,
  Text,
  Button,
  ButtonGroup,
  Icon,
  Divider,
  CheckBox,
  Card,
} from '@ui-kitten/components';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { UIStatusWrapper } from './ui-status';
import { warehousesStore } from '../store';
import { observer } from 'mobx-react-lite';
import { unifiedAlert } from '../core/utils/utils';
import { TextInputField } from './input/TextInputField';
import {
  SHIPPING_CHANNEL_CODE,
  SHIPPING_PROVIDER_CONFIG_SWITCH,
  EXCEL_LENGTH_LIMIT,
  BORDER_EXPRESS_SERVICE_TYPE,
} from '@ezom/library/lib/cjs/constants';
import Select from './Select';
import { pick } from 'ramda';
import Autocomplete from './Autocomplete';

const UNIVERSAL_FIELDS = [
  'provider',
  'name',
  'warehouse_code',
  'autoPack',
  'supportPacking',
  'singlePackageOnly',
  'requireExportInfo',
  'allowUserShipmentOrder',
  'chargeByCubicWeight',
  'cubicWeightCoefficient',
  'deliveryWindow',
  'dangerousGoods',
  'handUnload',
  'tailgate',
];

const SHIPPING_PROVIDERS = Object.keys(SHIPPING_PROVIDER_CONFIG_SWITCH);

const TEST_SHIPPING_CHANNELS = {
  [SHIPPING_CHANNEL_CODE.AUSTRALIA_POST_EPARCEL]: {
    name: 'Test Australia Post eParcel',
    apiUrl: 'https://digitalapi.auspost.com.au/test/shipping/v1',
    productId: '7E55',
    apiKey: '33067f02-5b60-4bc9-9a2e-c6c5a5fdbf65',
    apiPassword: 'pC0HcP90YcdrdQyjnDHp',
    accountNumber: '2001059808',
  },
  [SHIPPING_CHANNEL_CODE.AUSTRALIA_POST_EPARCEL_EXPRESS]: {
    name: 'Test AuPost eParcel Express',
    apiUrl: 'https://digitalapi.auspost.com.au/test/shipping/v1',
    productId: '3K55',
    apiKey: '33067f02-5b60-4bc9-9a2e-c6c5a5fdbf65',
    apiPassword: 'pC0HcP90YcdrdQyjnDHp',
    accountNumber: '2001059808',
  },
  [SHIPPING_CHANNEL_CODE.AUSPOST_INTERNATIONAL]: {
    name: 'Test Australia Post International',
    apiUrl: 'https://digitalapi.auspost.com.au/test/shipping/v1',
    productId: 'PTI8',
    apiKey: '33067f02-5b60-4bc9-9a2e-c6c5a5fdbf65',
    apiPassword: 'pC0HcP90YcdrdQyjnDHp',
    accountNumber: '2001059808',
  },
  [SHIPPING_CHANNEL_CODE.ETOWER]: {
    name: 'Test Etower',
    apiUrl: 'https://digitalapi.auspost.com.au/test/shipping/v1',
    productId: 'PTI8',
    apiKey: '33067f02-5b60-4bc9-9a2e-c6c5a5fdbf65',
    apiPassword: 'pC0HcP90YcdrdQyjnDHp',
    accountNumber: '2001059808',
    apiUrl: 'http://qa.etowertech.com',
    apiToken: 'test5AdbzO5OEeOpvgAVXUFE0A',
    apiSecret: '79db9e5OEeOpvgAVXUFWSD',
    serviceCode: 'GEN.AU2AU.AUPOST',
  },
  // Attention: Allied requires IP addresses to be whitelisted before they can be used to access the Allied API
  // This means you can only test the Allied API from the dev server, or contact Allied to whitelist your IP address
  [SHIPPING_CHANNEL_CODE.ALLIED_EXPRESS]: {
    name: 'Test Allied Express',
    wsdlUrl: 'http://triton.alliedexpress.com.au:8080/ttws-ejb/TTWS?wsdl',
    webServiceUrl: 'http://triton.alliedexpress.com.au:8080/ttws-ejb/TTWS',
    signatureCode: '1997a85cb22d30360dbf17d43419da8b',
    accountCode: 'MATLOG',
    accountState: 'ACT',
    serviceCode: 'AOE',
    serviceLevel: 'R',
  },
  [SHIPPING_CHANNEL_CODE.BLUE_STAR]: {
    name: 'Test BLUE STAR',
    webServiceUrl: '3.24.176.214',
    accountCode: 'TESTACC',
    username: 'ezomuser',
    password: 'JNh.osYYt.u@C.EeW!4t.LTx',
    ftpDirectory: 'manifest',
  },
  [SHIPPING_CHANNEL_CODE.SELF_COLLECTION]: {
    name: 'Self-collection',
  },
  [SHIPPING_CHANNEL_CODE.AML_TRUCK]: {
    name: 'AML Truck',
  },
  [SHIPPING_CHANNEL_CODE.JDL]: {
    name: 'JDL',
  },
  [SHIPPING_CHANNEL_CODE.XFM]: {
    name: 'XFM',
    apiUrl: 'https://api.transvirtual.com.au',
    apiKey: '1-443572|YRGWVTCMCV',
  },
  [SHIPPING_CHANNEL_CODE.BONDS]: {
    name: 'BONDS',
    apiUrl: 'https://appsrv.bondscouriers.com.au/bondsweb/api/upload-xml-job.htm',
    apiKey: 'DUMMY',
    apiPassword: '56789',
  },
  [SHIPPING_CHANNEL_CODE.BORDER_EXPRESS]: {
    name: 'BORDER EXPRESS',
    apiUrl: 'https://api.borderexpress.com.au',
    serviceType: 'PAR',
    apiKey: '719f385a.fb00.454a.a4e9.e26d6563465a',
    apiSecret: 'AOf8CuU9J3/rhX5xKEgbPYv+pEX/bumH',
    sftpAddress: 'test',
    numberPrefix: 'JDL',
    accountCode: 'test',
  },
};

const ShippingChannelConfigInput = observer(
  ({
    onSubmit,
    size = 'medium',
    style = {},
    onCancel,
    channelConfig = null,
    channelNameEditable = true,
    allowDuplicateName = false,
    title = 'Add shipping channel',
    warehouseAccountId,
  }) => {
    const warehouses = warehousesStore.warehousesByAccountId[warehouseAccountId] || [];
    const warehouseNames = useMemo(() => {
      return warehouses.map((w) => w.warehouse_name_en);
    }, [warehouses]);
    return (
      <Layout style={style} level="1">
        <UIStatusWrapper
          status={{
            error: warehousesStore.error,
            indeterminate: warehousesStore.loading,
          }}>
          <Formik
            initialValues={{
              provider: channelConfig?.provider || '',
              name: channelConfig?.name || '',
              warehouse_code: channelConfig?.warehouse_code || '',
              autoPack: channelConfig?.autoPack ?? false,
              supportPacking: channelConfig?.supportPacking ?? false,
              singlePackageOnly: channelConfig?.singlePackageOnly ?? false,
              requireExportInfo: channelConfig?.requireExportInfo ?? false,
              allowUserShipmentOrder: channelConfig?.allowUserShipmentOrder ?? false,
              chargeByCubicWeight: channelConfig?.chargeByCubicWeight ?? false,
              cubicWeightCoefficient: channelConfig?.cubicWeightCoefficient ?? '',
              deliveryWindow: channelConfig?.deliveryWindow || false,
              dangerousGoods: channelConfig?.dangerousGoods || false,
              handUnload: channelConfig?.handUnload || false,
              tailgate: channelConfig?.tailgate || false,

              apiUrl: channelConfig?.apiUrl || '',
              apiKey: channelConfig?.apiKey || '',
              apiPassword: channelConfig?.apiPassword || '',
              apiToken: channelConfig?.apiToken || '',
              apiSecret: channelConfig?.apiSecret || '',
              serviceCode: channelConfig?.serviceCode || '',
              serviceOption: channelConfig?.serviceOption || '',
              facility: channelConfig?.facility || '',
              accountNumber: channelConfig?.accountNumber || '',
              productId: channelConfig?.productId || '',
              wsdlUrl: channelConfig?.wsdlUrl || '',
              webServiceUrl: channelConfig?.webServiceUrl || '',
              signatureCode: channelConfig?.signatureCode || '',
              accountCode: channelConfig?.accountCode || '',
              accountState: channelConfig?.accountState || '',
              serviceLevel: channelConfig?.serviceLevel || '',
              username: channelConfig?.username || '',
              password: channelConfig?.password || '',
              ftpDirectory: channelConfig?.ftpDirectory || '',
              serviceType: channelConfig?.serviceType || '',
              numberPrefix: channelConfig?.numberPrefix || '',
              sftpAddress: channelConfig?.sftpAddress || '',
            }}
            isInitialValid={false}
            validationSchema={Yup.object({
              provider: Yup.string().trim().required().oneOf(SHIPPING_PROVIDERS),
              name: allowDuplicateName
                ? Yup.string().trim().required().max(EXCEL_LENGTH_LIMIT)
                : Yup.string().trim().required().max(EXCEL_LENGTH_LIMIT),
              warehouse_code: Yup.string(),
              autoPack: Yup.boolean().required(),
              supportPacking: Yup.boolean().required(),
              singlePackageOnly: Yup.boolean().required(),
              requireExportInfo: Yup.boolean().required(),
              allowUserShipmentOrder: Yup.boolean().required(),
              chargeByCubicWeight: Yup.boolean().required(),
              cubicWeightCoefficient: Yup.number()
                .min(0, 'must be larger than 0')
                .when('chargeByCubicWeight', {
                  is: true,
                  then: (schema) => schema.required(),
                }),
              deliveryWindow: Yup.boolean().required(),
              dangerousGoods: Yup.boolean().required(),
              handUnload: Yup.boolean().required(),
              tailgate: Yup.boolean().required(),

              apiUrl: Yup.string()
                .url('Not a valid url')
                .when('provider', {
                  is: (val) => SHIPPING_PROVIDER_CONFIG_SWITCH[val]?.apiUrl?.required,
                  then: (schema) => schema.required(),
                }),
              apiKey: Yup.string().when('provider', {
                is: (val) => SHIPPING_PROVIDER_CONFIG_SWITCH[val]?.apiKey?.required,
                then: (schema) => schema.required(),
              }),
              apiPassword: Yup.string().when('provider', {
                is: (val) => SHIPPING_PROVIDER_CONFIG_SWITCH[val]?.apiPassword?.required,
                then: (schema) => schema.required(),
              }),
              apiToken: Yup.string().when('provider', {
                is: (val) => SHIPPING_PROVIDER_CONFIG_SWITCH[val]?.apiToken?.required,
                then: (schema) => schema.required(),
              }),
              apiSecret: Yup.string().when('provider', {
                is: (val) => SHIPPING_PROVIDER_CONFIG_SWITCH[val]?.apiSecret?.required,
                then: (schema) => schema.required(),
              }),
              serviceCode: Yup.string().when('provider', {
                is: (val) => SHIPPING_PROVIDER_CONFIG_SWITCH[val]?.serviceCode?.required,
                then: (schema) => schema.required(),
              }),
              serviceOption: Yup.string().when('provider', {
                is: (val) => SHIPPING_PROVIDER_CONFIG_SWITCH[val]?.serviceOption?.required,
                then: (schema) => schema.required(),
              }),
              facility: Yup.string().when('provider', {
                is: (val) => SHIPPING_PROVIDER_CONFIG_SWITCH[val]?.faciity?.required,
                then: (schema) => schema.required(),
              }),
              accountNumber: Yup.string().when('provider', {
                is: (val) => SHIPPING_PROVIDER_CONFIG_SWITCH[val]?.accountNumber?.required,
                then: (schema) => schema.required(),
              }),
              productId: Yup.string().when('provider', {
                is: (val) => SHIPPING_PROVIDER_CONFIG_SWITCH[val]?.productId?.required,
                then: (schema) => schema.required(),
              }),
              numberPrefix: Yup.string().when('provider', {
                is: (val) => SHIPPING_PROVIDER_CONFIG_SWITCH[val]?.numberPrefix?.required,
                then: (schema) => schema.required().matches(/^\S*$/, 'Spaces are not allowed'),
              }),
              sftpAddress: Yup.string().when('provider', {
                is: (val) => SHIPPING_PROVIDER_CONFIG_SWITCH[val]?.sftpAddress?.required,
                then: (schema) => schema.required(),
              }),
            })}
            onSubmit={async (values, formikActions) => {
              const keys = [
                ...Object.keys(SHIPPING_PROVIDER_CONFIG_SWITCH[values.provider]),
                ...UNIVERSAL_FIELDS,
              ];
              const payload = {
                provider: values.provider.trim(),
                name: values.name.trim(),
                warehouse_code: values.warehouse_code.trim(),
                apiUrl: values.apiUrl.trim(),
                apiKey: values.apiKey.trim(),
                apiPassword: values.apiPassword.trim(),
                apiToken: values.apiToken.trim(),
                apiSecret: values.apiSecret.trim(),
                serviceType: values.serviceType.trim(),
                sftpAddress: values.sftpAddress.trim(),
                numberPrefix: values.numberPrefix.trim(),
                serviceCode: values.serviceCode.trim(),
                serviceOption: values.serviceOption.trim(),
                facility: values.facility.trim(),
                accountNumber: values.accountNumber.trim(),
                productId: values.productId.trim(),
                wsdlUrl: values.wsdlUrl.trim(),
                webServiceUrl: values.webServiceUrl.trim(),
                signatureCode: values.signatureCode.trim(),
                accountCode: values.accountCode.trim(),
                accountState: values.accountState.trim(),
                serviceLevel: values.serviceLevel.trim(),
                username: values.username.trim(),
                password: values.password.trim(),
                ftpDirectory: values.ftpDirectory.trim(),
                autoPack: values.autoPack,
                supportPacking: values.supportPacking,
                singlePackageOnly: values.singlePackageOnly,
                requireExportInfo: values.requireExportInfo,
                allowUserShipmentOrder: values.allowUserShipmentOrder,
                chargeByCubicWeight: values.chargeByCubicWeight,
                cubicWeightCoefficient: parseFloat(values.cubicWeightCoefficient.toString().trim()),
                deliveryWindow: values.deliveryWindow,
                deliveryWindowStart: values.deliveryWindowStart?.trim(),
                deliveryWindowEnd: values.deliveryWindowEnd?.trim(),
                dangerousGoods: values.dangerousGoods,
                handUnload: values.handUnload,
                tailgate: values.tailgate,
              };
              try {
                await onSubmit(pick(keys, payload));
                formikActions.resetForm();
              } catch (e) {
                unifiedAlert(e);
              }
              // Important: Make sure to setSubmitting to false so our loading indicator
              // goes away.
              formikActions.setSubmitting(false);
            }}>
            {(props) => {
              return (
                <Layout style={styles.formContainer}>
                  <Text category="s1" style={styles.title}>
                    {title}
                  </Text>
                  <Divider />
                  <Select
                    label="Provider"
                    name="provider"
                    size={size}
                    disabled={!!channelConfig}
                    style={styles.field}
                    placeholder="Select a provider"
                    options={SHIPPING_PROVIDERS.map((s) => ({
                      key: s,
                      val: s,
                    }))}
                    value={props.values.provider}
                    onBlur={() => props.setFieldTouched('provider', true)}
                    onChange={(val) => {
                      props.setFieldValue('provider', val.key);
                    }}
                  />
                  {!channelConfig &&
                  props.values.provider &&
                  TEST_SHIPPING_CHANNELS[props.values.provider] ? (
                    <Button
                      appearance="ghost"
                      status="danger"
                      size="small"
                      onPress={() => {
                        props.setValues({
                          ...props.values,
                          ...TEST_SHIPPING_CHANNELS[props.values.provider],
                        });
                      }}>
                      Use test config
                    </Button>
                  ) : null}
                  <TextInputField
                    label="Name"
                    name="name"
                    size={size}
                    style={styles.field}
                    disabled={!channelNameEditable}
                    placeholder="Parcel Channel"
                    {...props}
                  />
                  <Text appearance="hint" style={{ fontSize: 13, marginLeft: 10 }}>
                    Leave warehouse blank to make it a global shipping channel
                  </Text>
                  <Autocomplete
                    label="Warehouse"
                    defaultValue={
                      warehouses.find((w) => w.warehouse_code === props.values.warehouse_code)
                        ?.warehouse_name_en || props.values.warehouse_code
                    }
                    error={props.errors.warehouse_code}
                    touched={props.touched.warehouse_code}
                    placeholder="Select a warehosue"
                    style={styles.field}
                    options={warehouseNames}
                    onSelect={(val) => {
                      const value =
                        warehouses.find((w) => w.warehouse_name_en === val)?.warehouse_code || val;
                      props.setFieldValue('warehouse_code', value);
                    }}
                    onBlur={props.handleBlur('warehouse_code')}
                  />
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.apiUrl && (
                    <TextInputField
                      label="API URL"
                      name="apiUrl"
                      size={size}
                      style={styles.field}
                      placeholder="https://api.example.com/api"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.wsdlUrl && (
                    <TextInputField
                      label="WSDL URL"
                      name="wsdlUrl"
                      size={size}
                      style={styles.field}
                      placeholder="https://api.example.com/api?wsdl"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.webServiceUrl && (
                    <TextInputField
                      label="Web Service URL"
                      name="webServiceUrl"
                      size={size}
                      style={styles.field}
                      placeholder="https://api.example.com/api"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.apiKey && (
                    <TextInputField
                      label="API Key"
                      name="apiKey"
                      size={size}
                      style={styles.field}
                      placeholder="1234567890abc"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.signatureCode && (
                    <TextInputField
                      label="Signature Code"
                      name="signatureCode"
                      size={size}
                      style={styles.field}
                      placeholder="1234567890abc"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.apiPassword && (
                    <TextInputField
                      name="apiPassword"
                      label="API Password"
                      size={size}
                      style={styles.field}
                      keyboardType="visible-password"
                      placeholder="password"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.apiToken && (
                    <TextInputField
                      name="apiToken"
                      label="API Token"
                      size={size}
                      style={styles.field}
                      keyboardType="visible-password"
                      placeholder="token"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.apiSecret && (
                    <TextInputField
                      name="apiSecret"
                      label="API Secret"
                      size={size}
                      style={styles.field}
                      keyboardType="visible-password"
                      placeholder="secret"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.serviceCode && (
                    <TextInputField
                      label="Service code"
                      name="serviceCode"
                      size={size}
                      style={styles.field}
                      placeholder="S123"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.serviceOption && (
                    <TextInputField
                      label="Service option"
                      name="serviceOption"
                      size={size}
                      style={styles.field}
                      placeholder="Option123"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.facility && (
                    <TextInputField
                      label="Facility"
                      name="facility"
                      size={size}
                      style={styles.field}
                      placeholder=""
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.accountNumber && (
                    <TextInputField
                      name="accountNumber"
                      label="Account Number"
                      size={size}
                      style={styles.field}
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.accountCode && (
                    <TextInputField
                      name="accountCode"
                      label="Account Code"
                      size={size}
                      style={styles.field}
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.accountState && (
                    <TextInputField
                      name="accountState"
                      label="Account State"
                      size={size}
                      style={styles.field}
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.productId && (
                    <TextInputField
                      label="Product ID"
                      name="productId"
                      size={size}
                      style={styles.field}
                      placeholder="P123"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.serviceLevel && (
                    <TextInputField
                      label="Service Level"
                      name="serviceLevel"
                      size={size}
                      style={styles.field}
                      placeholder="R"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.username && (
                    <TextInputField
                      label="User name"
                      name="username"
                      size={size}
                      style={styles.field}
                      placeholder="test_user"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.password && (
                    <TextInputField
                      label="Password"
                      name="password"
                      size={size}
                      style={styles.field}
                      placeholder="password"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.numberPrefix && (
                    <TextInputField
                      label="Number Prefix"
                      name="numberPrefix"
                      size={size}
                      style={styles.field}
                      placeholder="ABC"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.serviceType && (
                    <Select
                      label="Service Type"
                      name="serviceType"
                      size={size}
                      style={styles.field}
                      options={Object.entries(BORDER_EXPRESS_SERVICE_TYPE).map(([key, val]) => ({
                        key,
                        val,
                      }))}
                      value={props.values.serviceType}
                      onBlur={() => props.setFieldTouched('serviceType', true)}
                      onChange={(val) => {
                        console.log(val);
                        props.setFieldValue('serviceType', val.key);
                      }}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.sftpAddress && (
                    <TextInputField
                      label="SFTP address"
                      name="sftpAddress"
                      size={size}
                      style={styles.field}
                      placeholder="ftp.example.com"
                      {...props}
                    />
                  )}
                  {SHIPPING_PROVIDER_CONFIG_SWITCH[props.values.provider]?.ftpDirectory && (
                    <TextInputField
                      label="FTP directory"
                      name="ftpDirectory"
                      size={size}
                      style={styles.field}
                      placeholder="/"
                      {...props}
                    />
                  )}

                  <Divider />

                  {props.values.chargeByCubicWeight && (
                    <TextInputField
                      label="Cubic weight coefficient"
                      name="cubicWeightCoefficient"
                      style={styles.field}
                      keyboardType="number-pad"
                      {...props}
                    />
                  )}

                  <Card
                    header={(props) => (
                      <Text {...props} category="s1">
                        Additional configs
                      </Text>
                    )}>
                    <Layout
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        flexWrap: 'wrap',
                      }}>
                      <CheckBox
                        size={size}
                        name="chargeByCubicWeight"
                        style={styles.field}
                        onChange={(checked) => props.setFieldValue('chargeByCubicWeight', checked)}
                        checked={props.values.chargeByCubicWeight}
                        {...props}>
                        <Text category="label">Cubic weight</Text>
                      </CheckBox>
                      <CheckBox
                        label="Auto-pack"
                        size={size}
                        name="autoPack"
                        style={styles.field}
                        checked={props.values.autoPack}
                        onChange={(checked) => props.setFieldValue('autoPack', checked)}
                        {...props}>
                        <Text category="label">Auto-pack</Text>
                      </CheckBox>
                      <CheckBox
                        size={size}
                        name="supportPacking"
                        style={styles.field}
                        checked={props.values.supportPacking}
                        onChange={(checked) => props.setFieldValue('supportPacking', checked)}
                        {...props}>
                        <Text category="label">Support packing</Text>
                      </CheckBox>
                      <CheckBox
                        size={size}
                        name="singlePackageOnly"
                        style={styles.field}
                        checked={props.values.singlePackageOnly}
                        onChange={(checked) => props.setFieldValue('singlePackageOnly', checked)}
                        {...props}>
                        <Text category="label">Single package only</Text>
                      </CheckBox>
                      <CheckBox
                        size={size}
                        name="requireExportInfo"
                        style={styles.field}
                        checked={props.values.requireExportInfo}
                        onChange={(checked) => props.setFieldValue('requireExportInfo', checked)}
                        {...props}>
                        <Text category="label">Export info</Text>
                      </CheckBox>
                      <CheckBox
                        size={size}
                        name="allowUserShipmentOrder"
                        style={styles.field}
                        checked={props.values.allowUserShipmentOrder}
                        onChange={(checked) =>
                          props.setFieldValue('allowUserShipmentOrder', checked)
                        }
                        {...props}>
                        <Text category="label">Allow shipment order</Text>
                      </CheckBox>
                    </Layout>
                  </Card>

                  <Card
                    style={{ marginTop: 10 }}
                    header={(props) => (
                      <Text {...props} category="s1">
                        Additional services
                      </Text>
                    )}>
                    <Layout
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        flexWrap: 'wrap',
                      }}>
                      <CheckBox
                        size={size}
                        name="deliveryWindow"
                        style={styles.field}
                        onChange={(checked) => props.setFieldValue('deliveryWindow', checked)}
                        checked={props.values.deliveryWindow}
                        {...props}>
                        <Text category="label">Delivery window</Text>
                      </CheckBox>
                      <CheckBox
                        size={size}
                        name="dangerousGoods"
                        style={styles.field}
                        checked={props.values.dangerousGoods}
                        onChange={(checked) => props.setFieldValue('dangerousGoods', checked)}
                        {...props}>
                        <Text category="label">Dangerous goods</Text>
                      </CheckBox>
                      <CheckBox
                        size={size}
                        name="handUnload"
                        style={styles.field}
                        checked={props.values.handUnload}
                        onChange={(checked) => props.setFieldValue('handUnload', checked)}
                        {...props}>
                        <Text category="label">Hand unload</Text>
                      </CheckBox>
                      <CheckBox
                        size={size}
                        name="tailgate"
                        style={styles.field}
                        checked={props.values.tailgate}
                        onChange={(checked) => props.setFieldValue('tailgate', checked)}
                        {...props}>
                        <Text category="label">Tailgate</Text>
                      </CheckBox>
                    </Layout>
                  </Card>
                  <Divider />
                  <ButtonGroup appearance="ghost" size={size} style={styles.btnContainer}>
                    <Button
                      appearance="outline"
                      status="primary"
                      accessoryLeft={(props) => <Icon {...props} name="save-outline" />}
                      loading={props.isSubmitting}
                      disabled={props.isSubmitting || !props.isValid}
                      onPress={props.handleSubmit}>
                      Save
                    </Button>
                    <Button
                      appearance="outline"
                      status="danger"
                      accessoryLeft={(innerProps) => (
                        <Icon {...innerProps} name="close-circle-outline" />
                      )}
                      loading={props.isSubmitting}
                      disabled={props.isSubmitting}
                      onPress={onCancel}>
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Layout>
              );
            }}
          </Formik>
        </UIStatusWrapper>
      </Layout>
    );
  },
);

const styles = StyleSheet.create({
  btnContainer: {
    justifyContent: 'space-evenly',
    marginVertical: 15,
  },
  field: {
    marginVertical: 8,
  },
  formContainer: {
    alignSelf: 'center',
    overflowY: 'auto',
  },
  title: {
    marginVertical: 10,
  },
});

export default memo(ShippingChannelConfigInput);
